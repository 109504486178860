<ejs-dialog
    	#modal
		(close)="closeModal()"
		cssClass="modal-lg"
		[allowDragging]="false"
>
	<ng-template #header>
		<div class="dlg-template">{{title}}</div>
	</ng-template>
	<ng-template #content>
		<ngx-extended-pdf-viewer
			#pdfViewer
			[src]="fileUrl"
			[filenameForDownload]="fileName"
			[handTool]="false"
			[showHandToolButton]="true"
			[showOpenFileButton]="false"
			[showPresentationModeButton]="true"
			[showDrawEditor]="false"
			[showTextEditor]="false"
			[showStampEditor]="false"
			[showHighlightEditor]="false"
			[printResolution]="600"
			[height]="'80vh'"
			(pagesLoaded)="onLoad()">
		</ngx-extended-pdf-viewer>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'pdfViewerCloseButton'">Close</button>
	</ng-template>
</ejs-dialog>
