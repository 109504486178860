<ng-container *ngFor="let iframe of iframeContainerService.iframes">
	<rev-loading-overlay *ngIf="iframe.isEnabled" [hidden]="!iframe.isActive" [loading]="!iframe.isLoaded">
		<div style="position: relative">
			<a *ngIf="iframe.openInTab" (click)="iframe.openInTab()" revTooltip [tooltipContent]="iframe.openInTabTooltip" style="position: absolute; top: 20px; right: 20px">
				<i class="fa fa-external-link fa-2x"></i>
			</a>
			<iframe
				*ngIf="iframe.url"
				[hidden]="!iframe.isLoaded"
				[src]="iframe.url"
				(load)="iframe.loaded($any($event.target))"
				[height]="iframeHeight"
				style="width: 100%; border: 0"
				referrerpolicy="no-referrer"></iframe>
		</div>
	</rev-loading-overlay>
</ng-container>
