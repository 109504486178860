<div class="col-sm-4" [attr.data-test-id]="'rxntLoginSection'">
	<h4 class="section-title bg-default mrgn-btm">RXNT Login Information</h4>
	<form *ngIf="showRxntCredential() else noRxntTemplate"
		  [formGroup]="getRxntCredentialFormGroup()">
		<div class="margin-bottom-md">
			<rev-status-select-button
				[options]="rxntStatuses"
				[formControl]="getRxntCredentialFormGroup().controls.enabled">
			</rev-status-select-button>
		</div>
		<div *ngIf="getRxntCredentialFormGroup().controls.enabled.value">
			<div class="form-group" [attr.data-test-id]="'rxntLoginNameFormGroup'">
				<label class="col-sm-3 control-label required">User Name</label>
				<div class="col-sm-9">
					<input class="e-input" [formControl]="getRxntCredentialFormGroup().controls.userName"/>
				</div>
			</div>
			<div class="form-group" [attr.data-test-id]="'rxntPasswordFormGroup'">
				<label class="col-sm-3 control-label required">Password</label>
				<div class="col-sm-9">
					<input type="password" class="e-input" [formControl]="getRxntCredentialFormGroup().controls.password"/>
				</div>
			</div>
		</div>
	</form>
	<ng-template #noRxntTemplate>
		<p class="text-muted">RxNT Must be enabled by practice and user must be a provider</p>
	</ng-template>
</div>
