<ejs-dialog #locationTaxModal
	cssClass="modal-sm"
	(close)="closeModal()"
	[attr.data-test-id]="'taxRateModal'">
	<ng-template #header>
		<div class="dlg-template">{{ isNewTax | yesNo: 'Add Tax Rate': 'Edit Tax Rate' }}</div>
	</ng-template>
	<ng-template #content>
		<form #componentForm="ngForm"
			[formGroup]="formGroup"
			(ngSubmit)="save()"
			class="form-horizontal">
			<gandalf-lib-validation-messages
				*ngIf="formGroup.invalid && componentForm.submitted"
				[form]="formGroup"
				[requestObj]="request"
				[attr.data-test-id]="'taxRateModalValidationMessages'">
			</gandalf-lib-validation-messages>
			<div class="form-group" *ngIf="showGroupTaxOption">
				<label class="col-sm-4 control-label required">Type</label>
				<div class="col-sm-8">
					<rev-status-select-button
							[options]="groupTaxOptions"
							(onChange)="clearFormData($event)"
							[(ngModel)]="isGroupTax"
							[ngModelOptions]="{standalone: true}"
							[attr.data-test-id]="'taxRateModalTypeButtons'"
					></rev-status-select-button>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 control-label required">Name</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="name"/>
				</div>
			</div>
			<div class="form-group" *ngIf="!isGroupTax">
				<label class="col-sm-4 control-label required">Rate (%)</label>
				<div class="col-sm-8">
					<ejs-numerictextbox format="##0.###'%'" min="0" max="100"
						validateDecimalOnType="true" decimals="3" [showSpinButton]="false"
						formControlName="rate"></ejs-numerictextbox>
				</div>
			</div>
			<div class="form-group">
				<label class="col-sm-4 control-label">Description</label>
				<div class="col-sm-8">
					<input type="text" class="e-input" formControlName="description"/>
				</div>
			</div>
			<div class="form-group" *ngIf="isGroupTax" [attr.data-test-id]="'taxRatesMultiSelectDropdown'">
				<label class="col-sm-4 control-label required">Tax Rates</label>
				<div class="col-sm-8">
					<ejs-multiselect
							[dataSource]="availableTaxes"
							[showDropDownIcon]="true"
							formControlName="childTaxIds"
							showSelectAll="false"
							filterBarPlaceholder="Search"
							placeholder="Select Tax Rates"
							mode="CheckBox"
							[fields]="{text: 'label', value: 'value'}">
					</ejs-multiselect>
				</div>
			</div>
		</form>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" type="button" (click)="closeModal()" [attr.data-test-id]="'taxRateModalCancelButton'">Cancel</button>
		<button ejs-button isPrimary="true" type="button" (click)="submitForm($event)" [attr.data-test-id]="'taxRateModalSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

