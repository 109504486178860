import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { FormUtilsService } from '@core/form-utils/form-utils.service';
import { _cloneDeep, _findIndex, _isNil } from '@core/lodash/lodash';
import { PrescriptionUtil } from '@core/prescription-util/prescription-util';
import { ReferenceDataService } from '@core/reference-data/reference-data.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PreferenceDefaults, PreferenceName, PrescriptionAuthorizationType, ReferenceDataMasterCategory } from '@gandalf/constants';
import { CreateEyeglassPrescriptionBaseRequest } from '@gandalf/model/create-eyeglass-prescription-base-request';
import { CreateEyeglassPrescriptionRequest } from '@gandalf/model/create-eyeglass-prescription-request';
import { CreateEyeglassPrescriptionsRequest } from '@gandalf/model/create-eyeglass-prescriptions-request';
import { BaseComponent } from '@shared/component/base.component';
import { NUMBER_STEPPER_OPTIONS_TEMPLATE } from '@shared/component/number-stepper/number-stepper-options.constants';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import Big from 'big.js';
import { GandalfFormBuilder } from 'gandalf';
import { DynamicModalRef, GRID_MULTILINE_ROW_HEIGHT, GridUtil, ModalConfig, ModalManagerService, OptionItem } from 'morgana';
import { EventService } from '@core/event/event.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { LocationLevelPrescriptionExpirationRequest } from '@gandalf/model/location-level-prescription-expiration-request';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { EyeglassPrescriptionService } from '../../../patients/prescription/eyeglass-prescription.service';

export interface CreateEyeglassPrescriptionForTable extends CreateEyeglassPrescriptionRequest {
	type: EYEGLASS_TYPE_KEY;
	typeLabel: string;
	add: number;
}

export enum EYEGLASS_TYPE_KEY {
	DISTANCE_ONLY,
	READING_ONLY,
	COMPUTER_SV,
	BLANK_OPTION_1,
	BLANK_OPTION_2,
}

export const EYEGLASS_TYPE = [
	{label: 'Distance Only', key: EYEGLASS_TYPE_KEY.DISTANCE_ONLY},
	{label: 'Reading Only', key: EYEGLASS_TYPE_KEY.READING_ONLY},
	{label: 'Computer (SV)', key: EYEGLASS_TYPE_KEY.COMPUTER_SV},
	{label: '', key: EYEGLASS_TYPE_KEY.BLANK_OPTION_1},
	{label: '', key: EYEGLASS_TYPE_KEY.BLANK_OPTION_2},
];

@Component({
	selector: 'pms-eyeglass-prescriptions-modal',
	templateUrl: './create-eyeglass-prescriptions-modal.component.html',
})

export class CreateEyeglassPrescriptionsModalComponent extends BaseComponent implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	@ViewChild('usedFor')
	usedForColumn: TemplateRef<any>;

	@ViewChild('eye')
	eyeColumn: TemplateRef<any>;

	@ViewChild('sphere')
	sphereColumn: TemplateRef<any>;

	@ViewChild('cylinder')
	cylinderColumn: TemplateRef<any>;

	@ViewChild('axis')
	axisColumn: TemplateRef<any>;

	@ViewChild('nearAdd')
	nearAddColumn: TemplateRef<any>;

	@ViewChild('hPrism')
	hPrismColumn: TemplateRef<any>;

	@ViewChild('vPrism')
	vPrismColumn: TemplateRef<any>;

	gridColumns: ColDef[];

	eyeglassPrescriptionGridOptions = GridUtil.buildGridOptions({
		suppressRowClickSelection: true,
		rowHeight: GRID_MULTILINE_ROW_HEIGHT,
	});

	formGroup: UntypedFormArray;
	prescriptions: CreateEyeglassPrescriptionForTable[];
	prescriptionUtil = PrescriptionUtil;
	prescription: object;
	usedForValues: OptionItem[];
	eyeGlassStepperOptions = NUMBER_STEPPER_OPTIONS_TEMPLATE.HYBRID_CONTACT;
	originalSphereOD: number;
	originalSphereOS: number;
	originalPrescriptionRef: CreateEyeglassPrescriptionBaseRequest;
	patientId: number;
	encounterId: number;
	expirationLocationPreference: string;
	readonly locationExpirationFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.LOCATION_LEVEL_EXPIRATION));

	get showCreateButton() {
		return this.getGridSelectedRecords().length > 0;
	}

	get showCreateAuthOnlyButton() {
		return this.showCreateButton && this.securityManagerService.userIsProvider();
	}

	get showCreateAuthAndSignButton() {
		return this.showCreateAuthOnlyButton
			&& !this.securityManagerService.preferenceValueIsOn(
				PreferenceName.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.value,
				PreferenceDefaults.PROVIDER_ALWAYS_USE_SIGNATURE_IMAGE.value,
			);
	}

	constructor(
		public dynamicModalRef: DynamicModalRef,
		public modalConfig: ModalConfig,
		public modalManagerService: ModalManagerService,
		public referenceDataService: ReferenceDataService,
		public gandalfFormBuilder: GandalfFormBuilder,
		public eyeglassPrescriptionService: EyeglassPrescriptionService,
		public securityManagerService: SecurityManagerService,
		private eventService: EventService,
	) {
		super();
	}

	ngOnInit(): void {
		this.parseConfigData(this.modalConfig.data);
		this.getData();
		this.createForm();
		this.observeFormChange();
	}

	parseConfigData(data) {
		const createEyeglassPrescriptionRequest: CreateEyeglassPrescriptionRequest = data.createEyeglassPrescriptionRequest;
		this.prescriptions = [];
		this.patientId = createEyeglassPrescriptionRequest.patientId;
		this.encounterId = createEyeglassPrescriptionRequest.encounterId;

		// Don't create data if all fields are null
		if (this.isPrescriptionEmpty(createEyeglassPrescriptionRequest)) {
			return;
		}
		// There could be two individual nearAdd for OD and OS, use OD's in that case.
		const add = createEyeglassPrescriptionRequest.od.nearAdd || createEyeglassPrescriptionRequest.os.nearAdd;

		// Show 0.00 if spheres are null
		createEyeglassPrescriptionRequest.od.sphere = createEyeglassPrescriptionRequest.od.sphere || 0.00;
		createEyeglassPrescriptionRequest.os.sphere = createEyeglassPrescriptionRequest.os.sphere || 0.00;

		this.originalSphereOD = createEyeglassPrescriptionRequest.od.sphere;
		this.originalSphereOS = createEyeglassPrescriptionRequest.os.sphere;
		this.originalPrescriptionRef = createEyeglassPrescriptionRequest;

		for (const type of EYEGLASS_TYPE) {
			// Don’t build a prescription for Reading Only or Computer SV
			if ((_isNil(add) || add === 0) && (type.key === EYEGLASS_TYPE_KEY.READING_ONLY || type.key === EYEGLASS_TYPE_KEY.COMPUTER_SV)) {
				continue;
			}
			const request = _cloneDeep(createEyeglassPrescriptionRequest);

			let prescription;
			// distance_only type doesn't have nearAdd
			if (type.key === EYEGLASS_TYPE_KEY.DISTANCE_ONLY) {
				prescription = this.buildPrescriptionForTable(request, type);
			} else {
				// only computer SV has approximate half of the initial nearAdd
				const nearAdd = type.key === EYEGLASS_TYPE_KEY.COMPUTER_SV ? this.calculateAddForComputer(add) : add;
				prescription = this.buildPrescriptionForTable(request, type, nearAdd);
			}
			this.prescriptions.push(prescription);
		}
	}

	buildPrescriptionForTable(prescription, type, add?) {
		if (type.key === EYEGLASS_TYPE_KEY.READING_ONLY || type.key === EYEGLASS_TYPE_KEY.COMPUTER_SV) {
			prescription.od.sphere += add;
			prescription.os.sphere += add;
		}
		return {
			...prescription,
			type: type.key,
			typeLabel: type.label,
			add,
		};
	}

	isPrescriptionEmpty(request) {
		const {od, os} = request;
		return _isNil(od.sphere) && _isNil(os.sphere)
			&& _isNil(od.axis) && _isNil(os.axis)
			&& _isNil(od.cylinder) && _isNil(os.cylinder)
			&& _isNil(od.horizontalPrism) && _isNil(os.horizontalPrism)
			&& _isNil(od.verticalPrism) && _isNil(os.verticalPrism);
	}

	getData() {
		this.referenceDataService
			.getActiveReferenceDataByCategoryIdForDropdown(ReferenceDataMasterCategory.EYEGLASS_RX_USED_FOR_REASONS.value)
			.subscribe((usedForValues) => {
				this.usedForValues = usedForValues;
			});
		if (this.locationExpirationFeatureOn) {
			const locationLevelPrescriptionExpirationRequest = new LocationLevelPrescriptionExpirationRequest();
			locationLevelPrescriptionExpirationRequest.encounterId = this.encounterId;
			locationLevelPrescriptionExpirationRequest.patientId = this.patientId;
			this.eyeglassPrescriptionService.findEyeglassPrescriptionExpirationLocationPreference(locationLevelPrescriptionExpirationRequest)
				.subscribe(preference => this.expirationLocationPreference = preference);
		}
	}

	createForm() {
		this.formGroup = this.buildFormArray();
		this.formGroup.disable();
	}

	buildFormArray() {
		const formGroups = this.prescriptions.map(rx => {
			const request = new CreateEyeglassPrescriptionBaseRequest();
			request.od = rx.od;
			request.os = rx.os;
			request.od.nearAdd = rx.add;
			return this.gandalfFormBuilder.group(request);
		});

		return new UntypedFormArray(formGroups, null, null);
	}

	observeFormChange() {
		this.formGroup.controls.forEach((control, index) => {
			FormUtilsService.reactToValueChanges(this.formGroup.get(`${index}.od.nearAdd`), (addValue: number) => {
				if (this.isAggregateRow(index)) {
					this.prescriptions[index].od.sphere = this.originalSphereOD + addValue;
					this.prescriptions[index].os.sphere = this.originalSphereOS + addValue;
					this.agGrid.api.refreshCells({
						columns: ['sphere'],
						force: true,
					});
				}
			},
			false,
			this.unsubscribe,
			);
		});
	}

	onGridReady(_event: GridReadyEvent) {
		this.buildGridColumns();
		this.agGrid.api.setColumnDefs(this.gridColumns);
		// on init we want to update the grid correctly
		this.updateMultipleEyeglassPrescriptions();
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildCheckboxSelectionColumn({
				cellClass: 'display-flex align-items-center',
			}),
			GridUtil.buildColumn('Type', 'typeLabel', {
				minWidth: 120,
				flex: 1,
				lockPosition: true,
				sortable: false,
				cellClass: 'display-flex align-items-center',
			}),
			GridUtil.buildTemplateColumn('Used For', '', this.usedForColumn, {
				minWidth: 170,
				sortable: false,
				flex: 2,
				cellClass: 'display-flex align-items-center',
			}),
			GridUtil.buildTemplateColumn('Add', 'nearAdd', this.nearAddColumn, {
				width: 120,
				sortable: false,
				cellClass: 'display-flex align-items-center',
			}),
			GridUtil.buildTemplateColumn('', '', this.eyeColumn, {
				width: 50,
				sortable: false,
				resizable: false,
			}),
			GridUtil.buildTemplateColumn('Sph', 'sphere', this.sphereColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Cyl', 'cylinder', this.cylinderColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('Axis', 'axis', this.axisColumn, {
				width: 60,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('H Prism', '', this.hPrismColumn, {
				width: 100,
				sortable: false,
			}),
			GridUtil.buildTemplateColumn('V Prism', '', this.vPrismColumn, {
				width: 100,
				sortable: false,
			}),
		];
	}

	agGridRowSelected(event: RowSelectedEvent) {
		if (event.node.isSelected()) {
			this.getFormControl(event.data, 'usedForId').enable();
			this.getFormControl(event.data, 'od').enable();
		} else {
			this.getFormControl(event.data, 'usedForId').disable();
			this.getFormControl(event.data, 'od').disable();
		}
	}

	getFormControl(item, path) {
		const index = _findIndex(this.prescriptions, prescription => prescription.type === item.type);
		return this.formGroup.get(`${index}.${path}`);
	}

	updateMultipleEyeglassPrescriptions() {
		if (!_isNil(this.agGrid?.api)) {
			this.agGrid.api.setRowData(this.prescriptions || []);
		}
	}

	closeModal(refresh = false) {
		if (refresh) {
			this.prescriptionUpdated();
		}
		this.dynamicModalRef.close(this.modal, refresh);
	}

	calculateAddForComputer(add: number) {
		if (Big(add).lte(Big(0.25))) {
			return add;
		}

		return Number(Big(add).div(0.25).round(0, Big.roundDown).div(2).round(0, Big.roundDown).mul(0.25));
	}

	isAggregateRow(index: number) {
		const prescriptionItem = this.prescriptions[index];
		return prescriptionItem.type === EYEGLASS_TYPE_KEY.READING_ONLY || prescriptionItem.type === EYEGLASS_TYPE_KEY.COMPUTER_SV;
	}

	getGridSelectedRecords() {
		return !_isNil(this.agGrid?.api) ? this.agGrid.api.getSelectedRows() as CreateEyeglassPrescriptionForTable[] : [];
	}

	create(auth: boolean = false, sign: boolean = false) {
		const request = new CreateEyeglassPrescriptionsRequest();
		request.authorizePrescription = auth;
		request.useProviderSignature = sign;
		request.eyeglassPrescriptions = this.buildEyeglassPrescriptionRequests(this.getGridSelectedRecords());
		this.eyeglassPrescriptionService.createEyeglassPrescriptions(request).subscribe({
			complete: () => this.closeModal(true),
		});
	}

	setRequiredFieldsForCreateRequestAsNeeded(request: CreateEyeglassPrescriptionBaseRequest) {
		if (_isNil(request.startDate)) {
			request.startDate = new Date();
		}
		if (_isNil(request.expirationDate)) {
			request.expirationDate = PrescriptionUtil.getExpirationDate(
				request.startDate,
				this.expirationLocationPreference,
				this.securityManagerService.preferenceValue(PreferenceName.PRESCRIPTION_SPEC_EXP_MONTHS.value),
				PreferenceDefaults.PRESCRIPTION_SPEC_EXP_MONTHS.value,
			);
		}
		if (_isNil(request.uvTreatment)) {
			request.uvTreatment = false;
		}
		if (_isNil(request.prescriptionAuthorizationType)) {
			request.prescriptionAuthorizationType = PrescriptionAuthorizationType.PROVIDER;
		}
		if (_isNil(request.photochromic)) {
			request.photochromic = false;
		}
		if (_isNil(request.polarized)) {
			request.polarized = false;
		}
		if (_isNil(request.arCoating)) {
			request.arCoating = false;
		}
		return request;
	}

	buildEyeglassPrescriptionRequests(requests: CreateEyeglassPrescriptionForTable[]) {
		const retRequests = [];
		this.setRequiredFieldsForCreateRequestAsNeeded(this.originalPrescriptionRef);
		requests.forEach((request) => {
			const ref = _cloneDeep(this.originalPrescriptionRef);
			ref.od = this.getFormControl(request, 'od').value;
			ref.od.sphere = request.od.sphere;
			const nearAddDeleteForType = [EYEGLASS_TYPE_KEY.DISTANCE_ONLY, EYEGLASS_TYPE_KEY.READING_ONLY, EYEGLASS_TYPE_KEY.COMPUTER_SV];
			if (nearAddDeleteForType.includes(request.type)) {
				ref.od.nearAdd = null;
			}
			ref.os = this.getFormControl(request, 'os').value;
			ref.os.sphere = request.os.sphere;
			ref.os.nearAdd = ref.od.nearAdd;
			ref.usedForId = this.getFormControl(request, 'usedForId').value;
			retRequests.push(ref);
		});
		return retRequests;
	}

	prescriptionUpdated() {
		this.eventService.publishUpdatePatientEyeglassPrescriptions(this.patientId);
	}
}

