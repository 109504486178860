import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { InvoiceItemPricingUtil } from '@accounting/core/accounting/invoice-item-pricing/invoice-item-pricing-util';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { InvoiceDetailsModalComponent } from '@accounting/invoices/invoice-details-modal/invoice-details-modal.component';
import {
	ReceivePaymentsTransferItemsModalComponent,
	ReceivePaymentsTransferItemsModalResponse
} from '@accounting/invoices/receive-payment-transfer-items-modal/receive-payments-transfer-items-modal.component';
import { inject, Injectable } from '@angular/core';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { _isNil } from '@core/lodash/lodash';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { InvoiceItemPaymentRequest } from '@gandalf/model/invoice-item-payment-request';
import { PaymentResponse } from '@gandalf/model/payment-response';
import { ReceivePaymentTransferInvoiceItemsRequest } from '@gandalf/model/receive-payment-transfer-invoice-items-request';
import { PaymentTransferInvoiceItemsResponse } from '@gandalf/model/payment-transfer-invoice-items-response';
import {
	AccountingPaymentByItemPreferenceValues,
	PayerType,
	PaymentGroupSourceType,
	PaymentMethodType,
	PreferenceDefaults,
	PreferenceName,
	TransferType
} from '@gandalf/constants';
import { PaymentInvoiceListResponse } from '@gandalf/model/payment-invoice-list-response';
import { PatientNamePipe } from '@shared/pipes/patient-name/patient-name.pipe';
import Big from 'big.js';
import { EnumUtil, GridUtil, ModalManagerService, OptionItem, SortingService } from 'morgana';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';

/**
 * This interface is what the legacy event payer matches and it used to maintain backwards compatibility
 */
export interface ReceivePaymentsPayer {
	payerId?: number;
	entityId: number;
	type: PayerType;
	defaultPaymentMethod?: PaymentMethodType;
	name: string;
	sourceType: PaymentGroupSourceType;
	sourcePracticeLocationId?: number;
}

export interface InvoicePayment {
	invoiceId: number;
	total: number;
	patientName: string;
	balance: number;
	invoiceDate: Date;
	serviceDate: Date;
	itemsTotal: number;
	transferTotal: number;
	finalBalance: number;
	paymentAmount: number;
	selected: boolean;
	adjustmentTotal: number;
	transfersAndPayments: ReceivePaymentsTransferItemsModalResponse;
	processingPaymentGroupId: number;
}

export interface PaymentItemPermissions {
	allowPayByItem: boolean;
	allowTransferByItem: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class ReceivePaymentsService {

	readonly paymentsPerformanceFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ACCOUNTING.PAYMENTS.PERFORMANCE));

	constructor(
		private invoiceService: InvoiceService,
		private patientNamePipe: PatientNamePipe,
		private accountingService: AccountingService,
		private securityManager: SecurityManagerService,
	) {
	}

	/**
	 * Refresh the given invoice data and open the invoice detail modal in read only mode.
	 */
	openInvoiceDetailModal(modalManagerService: ModalManagerService, invoiceId: number) {
		this.invoiceService.refreshInvoice(invoiceId);
		return modalManagerService.open(InvoiceDetailsModalComponent, {data: {invoiceId, isReadOnly: true}}).onClose;
	}

	/**
	 * Refresh the given invoice data and open the transfer items modal.
	 */
	openTransferModal(
		modalManagerService: ModalManagerService,
		invoicePayment: InvoicePayment,
		bulkWriteOffs: OptionItem[],
		readOnly: boolean,
		paymentItemPermissions: PaymentItemPermissions,
	) {
		this.invoiceService.refreshInvoice(invoicePayment.invoiceId);
		return modalManagerService.open(ReceivePaymentsTransferItemsModalComponent, {
			data: {
				invoiceId: invoicePayment.invoiceId,
				invoicePaymentAmount: invoicePayment.paymentAmount,
				payments: invoicePayment.transfersAndPayments.paymentItemRequests,
				transfers: this.buildTransfersFromBulkWriteOffs(invoicePayment.transfersAndPayments.transferItemRequests, bulkWriteOffs),
				readOnly,
				allowItemsTransfer: paymentItemPermissions.allowTransferByItem,
				allowPaymentByItem: paymentItemPermissions.allowPayByItem,
			},
		}).onClose;
	}

	buildTransfersFromBulkWriteOffs(transfers: ReceivePaymentTransferInvoiceItemsRequest[], bulkWriteOffs: OptionItem[]) {
		if (bulkWriteOffs.length > 0) {
			const newTransfers = [];
			// build the bulk writeoffs into the transfers
			bulkWriteOffs.forEach(writeOff => {
				// Do not add a write off if there's already one existing with that reason
				if (_isNil(transfers.find(transfer => transfer.writeoffReferenceId === writeOff.value))) {
					const transferRequest = new ReceivePaymentTransferInvoiceItemsRequest();
					transferRequest.transferType = TransferType.WRITEOFF;
					transferRequest.writeoffReferenceId = writeOff.value;
					transferRequest.transferItems = [];
					newTransfers.push(transferRequest);
				}
			});
			transfers = [...transfers, ...newTransfers];
		}
		return transfers;
	}

	/**
	 * Build a new InvoicePayment from the given invoice data with all totals initialized to 0.
	 */
	buildNewInvoicePayment(invoice: PaymentInvoiceListResponse, selected: boolean): InvoicePayment {
		return {
			invoiceId: invoice.id,
			total: invoice.total,
			patientName: this.getPatientName(invoice),
			balance: invoice.balance,
			invoiceDate: invoice.invoiceDate,
			serviceDate: invoice.serviceDate,
			itemsTotal: 0,
			transferTotal: 0,
			finalBalance: invoice.balance,
			paymentAmount: 0,
			selected,
			adjustmentTotal: invoice.adjustmentTotal,
			transfersAndPayments: this.newTransferAndPayments(),
			processingPaymentGroupId: invoice.processingPaymentGroupId,
		};
	}

	/**
	 * Build a new payment/transfer items response with totals initialized to 0.
	 */
	newTransferAndPayments(): ReceivePaymentsTransferItemsModalResponse {
		return {
			paymentItemRequests: [],
			transferItemRequests: [],
			itemsPaidTotal: 0,
			transferTotal: 0,
		};
	}

	/**
	 * Build a selected InvoicePayment from an existing PaymentResponse.
	 */
	buildExistingInvoicePayment(payment: PaymentResponse, setSelected: boolean) {
		const invoicePayment = this.buildNewInvoicePayment(payment.invoice, setSelected);
		invoicePayment.paymentAmount = payment.amount;
		invoicePayment.transfersAndPayments = {
			transferItemRequests: payment.paymentTransfers as ReceivePaymentTransferInvoiceItemsRequest[],
			paymentItemRequests: payment.paymentItems as InvoiceItemPaymentRequest[],
			itemsPaidTotal: Number(payment.paymentItems ? GridUtil.sumCurrencyItems(payment.paymentItems, 'paymentAmount') : 0),
			transferTotal: Number(this.sumTransfers(payment.paymentTransfers)),
		};
		this.updateInvoicePaymentBalances(invoicePayment);
		return invoicePayment;
	}

	private sumTransfers(paymentTransfers: PaymentTransferInvoiceItemsResponse[]) {
		let sum = Big(0);
		paymentTransfers?.forEach(transfer => {
			sum = InvoiceItemPricingUtil.sumBalances([
				sum,
				transfer.transferAmount || GridUtil.sumCurrencyItems(transfer.transferItems, 'transferAmount'),
			]);
		});
		return sum;
	}

	/**
	 * Recalculate the payment/transfer item totals on the given InvoicePayment.
	 */
	updateInvoicePaymentBalances(invoicePayment: InvoicePayment) {
		invoicePayment.itemsTotal = invoicePayment.transfersAndPayments.itemsPaidTotal || 0;
		invoicePayment.transferTotal = invoicePayment.transfersAndPayments.transferTotal || 0;
		const paymentAmount = (invoicePayment.paymentAmount || 0);
		const paymentsAndTransfers = InvoiceItemPricingUtil.sumBalances([paymentAmount, invoicePayment.transferTotal]);
		invoicePayment.finalBalance = Number(InvoiceItemPricingUtil.calculateBalance(invoicePayment.balance, -(paymentsAndTransfers)));
	}

	getPatientName(invoice: PaymentInvoiceListResponse) {
		if (EnumUtil.equals(invoice.payerType, PayerType.ANONYMOUS)) {
			return PayerType.ANONYMOUS.label;
		} else {
			return this.patientNamePipe.transform(invoice.patientName);
		}
	}

	/**
	 * Find approved and outstanding invoices for the given payer at a location and convert them to InvoicePayments. If a given existing PaymentResponse
	 * matches the invoice; the InvoicePayment will be marked as selected and use that payment data.
	 */
	findInvoicePayments(locationId: number, payerEntityId: number, payerType: PayerType, existingPayments?: PaymentResponse[]) {
		let observable: Observable<PaymentInvoiceListResponse[]>;
		switch(payerType.value) {
			case PayerType.INSURANCE.value:
				observable = this.accountingService.findInsuranceInvoicesForPayment(locationId, payerEntityId);
				break;
			case PayerType.PATIENT.value:
				observable = this.accountingService.findPatientInvoicesForPayment(locationId, payerEntityId);
				break;
			case PayerType.ANONYMOUS.value:
				observable = this.accountingService.findAnonymousInvoicesForPayment(locationId, payerEntityId);
				break;
			case PayerType.COLLECTIONS.value:
				observable = this.accountingService.findCollectionsInvoicesForPayment(locationId, payerEntityId);
				break;
		}
		return observable.pipe(
			map(items => {
				// Invoice sort is different for a patient or insurance invoice
				if (EnumUtil.equals(payerType, PayerType.PATIENT)) {
					return SortingService.sortBy(items, ['invoiceDate', 'id'], ['desc', 'desc']);
				}
				// Need to sort by the displayed patient name
				return SortingService.sortBy(items, [item => this.getPatientName(item), 'invoiceDate', 'id'], ['asc', 'desc', 'desc']);
			}),
			map(invoices => invoices.map(invoice => {
				const existingPayment = existingPayments?.find(payment => payment.invoiceId === invoice.id);
				if (existingPayment) {
					const setSelected = _isNil(invoice.processingPaymentGroupId);
					return this.buildExistingInvoicePayment(existingPayment, setSelected);
				} else {
					return this.buildNewInvoicePayment(invoice, false);
				}
			})),
		);
	}

	/**
	 * Determines the line item permissions from the practice preferences. Each existing PaymentResponse will be checked for line item payments/transfers that
	 * may override the preferences.
	 */
	getPaymentItemPermissions(existingPayments: PaymentResponse[]): PaymentItemPermissions {
		let hasPaymentItems = false;
		let hasTransferItems = false;
		let hasInvoiceTransfer = false;
		existingPayments?.forEach(payment => {
			hasPaymentItems = hasPaymentItems || this.findNonZeroPaymentItem(payment);
			hasTransferItems = hasTransferItems || this.findNonZeroItemTransfer(payment);
			hasInvoiceTransfer = hasInvoiceTransfer || this.findNonZeroInvoiceTransfer(payment);
		});

		if (hasInvoiceTransfer) {
			return {allowPayByItem: false, allowTransferByItem: false};
		} else {
			const payByItemValues = AccountingPaymentByItemPreferenceValues;
			const payByItemPreference = EnumUtil.findEnumByValue(this.securityManager.preferenceValue(PreferenceName.ACCOUNTING_PAY_BY_ITEM.value), payByItemValues);
			const allowPayByItem = hasPaymentItems || EnumUtil.equalsOneOf(payByItemPreference, payByItemValues.OPTIONAL, payByItemValues.REQUIRED);
			const transferByItemPreference = this.securityManager.preferenceValueIsOn(
				PreferenceName.ACCOUNTING_TRANSFER_BY_ITEM.value,
				PreferenceDefaults.ACCOUNTING_TRANSFER_BY_ITEM.value,
			);
			const allowTransferByItem = allowPayByItem || hasTransferItems || transferByItemPreference;
			return {allowPayByItem, allowTransferByItem};
		}
	}

	findNonZeroPaymentItem(payment: PaymentResponse) {
		return !!payment.paymentItems?.find(item => !Big(item.paymentAmount || 0).eq(0));
	}

	findNonZeroItemTransfer(payment: PaymentResponse) {
		return !!payment.paymentTransfers.find(transfer => !!transfer.transferItems?.find(item => !Big(item.transferAmount || 0).eq(0)));
	}

	findNonZeroInvoiceTransfer(payment: PaymentResponse) {
		return !!payment.paymentTransfers.find(transfer => !Big(transfer.transferAmount || 0).eq(0));
	}
}
