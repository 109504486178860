<ejs-dialog
	#modal
	(close)="closeModal()"
	id="patient-letter-dockable-modal"
	cssClass="dockable-modal"
	[ngClass]="{'dialog-maximized': maximized, 'dialog-minimized': !maximized}"
	[attr.data-test-id]="'dockableLetterEditorModal'"
	[width]="900"
	[height]="'93%'"
	[allowDragging]="false"
	[isModal]="false"
	[position]="{X: 'right', Y: 'top'}">
	<rev-loading-overlay [loading]="_isLoading">
		<ng-template #header>
			<span class='title'>Write Letter</span>
			<span class="header-btns">
			<rev-button
				buttonIcon="window-maximize"
				dataTestId="letterEditorMaximizeButton"
				*ngIf="!maximized"
				(click)='maximize()'>
			</rev-button>
			<rev-button
				buttonIcon="window-minimize"
				dataTestId="letterEditorMinimizeButton"
				*ngIf="maximized"
				(click)='minimize()'>
			</rev-button>
		</span>
		</ng-template>
		<ng-template #content>
			<div class="modal-feature">
				<h4 class="margin-0">{{patientDocumentResponse?.documentName}}</h4>
			</div>
			<pms-text-editor *ngIf="!froalaDataLoading" #textEditor [content]="patientDocumentResponse?.documentContent"></pms-text-editor>
		</ng-template>
		<ng-template #footerTemplate>
			<rev-button
				buttonIcon="print"
				buttonLabel="Print"
				buttonStyle="primary"
				(buttonClick)="openPdfViewer(true)"
				dataTestId="letterEditorPrintButton">
			</rev-button>
			<rev-button
				buttonIcon="file-pdf-o"
				buttonLabel="PDF"
				buttonStyle="primary"
				(buttonClick)="openPdfViewer(false)"
				dataTestId="letterEditorPdfButton">
			</rev-button>
			<rev-button
				buttonType="submit"
				buttonLabel="Save Draft"
				buttonStyle="primary"
				(buttonClick)="updatePatientDocument()"
				dataTestId="letterEditorSaveButton">
			</rev-button>
		</ng-template>
	</rev-loading-overlay>
</ejs-dialog>
