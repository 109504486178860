<ejs-dialog
		#modal
		(close)="closeModal()"
		cssClass="modal-lg"
		[attr.data-test-id]="'selectADiagnosisModal'">
	<ng-template #header>
		<div class="dlg-template">Select a Diagnosis</div>
	</ng-template>
	<ng-template #content>
		<div class="row">
			<div class="col-sm-12 col-lg-6 margin-bottom-md">
				<form
						#templateForm="ngForm"
						(ngSubmit)="saveDiagnosesAndRefreshInvoice()"
						[formGroup]="componentForm"
						class="form-horizontal">
					<gandalf-lib-validation-messages
							*ngIf="componentForm.invalid && templateForm.submitted"
							[form]="componentForm"
							[requestObj]="invoiceDiagnosesRequest"
							[attr.data-test-id]="'selectADiagnosisModalValidationMessages'">
					</gandalf-lib-validation-messages>
					<div>
						<ag-grid-angular #selectedDiagnosesAgGrid
										 class="ag-theme-alpine"
										 pmsAgAutoFitColumns
										 [domLayout]="'autoHeight'"
										 [gridOptions]="selectedDxGridOptions"
										 [rowData]="selectedDiagnoses"
										 (rowDoubleClicked)="selectedDiagnosesGridDoubleclick($event)"
										 (gridReady)="onSelectedDiagnosesAgGridReady($event)"
										 [pagination]="false"
										 [attr.data-test-id]="'selectedDiagnosesTable'">
						</ag-grid-angular>
					</div>
				</form>
			</div>
			<div class="col-sm-12 col-lg-6">
				<div class="margin-bottom-sm" *ngIf="showIcd9Filter()" rev-table-action-menu-left>
					<ejs-checkbox [(ngModel)]="gridIcd9Filter" (ngModelChange)="filterAvailableDiagnosesAgGrid()" label="Show ICD-9 Codes" [attr.data-test-id]="'showIcd9CodesCheckBox'"></ejs-checkbox>
				</div>
				<ag-grid-angular #availableDiagnosesAgGrid
								 class="ag-theme-alpine"
								 pmsAgAutoFitColumns
								 [domLayout]="'autoHeight'"
								 [gridOptions]="availableDxGridOptions"
								 [rowData]="availableDiagnoses"
								 (rowDoubleClicked)="availableDiagnosesGridDoubleclick($event)"
								 (gridReady)="onAvailableDiagnosesAgGridReady($event)"
								 [pagination]="false"
								 (rowDataUpdated)="filterAvailableDiagnosesAgGrid()"
								 [attr.data-test-id]="'availableDiagnosesTable'">
				</ag-grid-angular>
			</div>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button ejs-button iconCss="fa fa-times" (click)="closeModal()" [attr.data-test-id]="'selectADiagnosisCancelButton'">Cancel</button>
		<button ejs-button type="submit" [isPrimary]="true" (click)="submitForm($event)" [attr.data-test-id]="'selectADiagnosisSaveButton'">Save</button>
	</ng-template>
</ejs-dialog>

<ng-template #diagnosesCodeColumnTemplate let-data>
		<span *ngIf="isCodeIcd10AndDisabled(data)" revTooltip tooltipContent="This code is no longer billable on/after {{data.practiceDiagnosis.disabledDate | date: dateFormat}}">
			{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}
		</span>
		<span *ngIf="!isCodeIcd10AndDisabled(data)" revTooltip tooltipContent="{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}">
			{{ data.practiceDiagnosis.code }} - {{data.practiceDiagnosis.shortDescription}}
		</span>
</ng-template>
<ng-template #diagnosesRemoveButtonColumnTemplate let-data>
	<rev-button
			[revGridButton]="'remove'"
			(buttonClick)="removeFromSelected(data)"
			dataTestId="diagnosesRemoveButton">
	</rev-button>
</ng-template>
<ng-template #diagnosesAddButtonColumnTemplate let-data>
	<rev-button
			[revGridButton]="'add'"
			(buttonClick)="addToSelected(data)"
			dataTestId="diagnosesAddButton">
	</rev-button>
</ng-template>
