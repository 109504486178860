import { UntypedFormGroup } from '@angular/forms';
import { GandalfFormArray, GandalfModelBase, ReadonlyGandalfFormModel, GandalfFormGroup } from 'gandalf';

/**
 * A type-safe wrapper for working with an Angular FormArray.
 * A TypedGandalfFormArray represents an array of {@link GandalfFormGroup}s
 */
export class TypedGandalfFormArray<T extends GandalfModelBase> {

	private _controls: GandalfFormGroup<T>[] = [];

	constructor(readonly formArray: GandalfFormArray) {
		for (const control of formArray.controls) {
			this._controls.push(new GandalfFormGroup<T>(control as UntypedFormGroup));
		}
	}

	/**
	 * Gets a reference to the backing {@link GandalfFormGroup}s as a readonly array
	 */
	get controls(): ReadonlyArray<GandalfFormGroup<T>> {
		return this._controls;
	}

	/**
	 * Adds an item to the form array
	 */
	pushItem(item: T) {
		const formGroup = this.formArray.pushRequestItem(item);
		const gandalfFormGroup = new GandalfFormGroup<T>(formGroup);
		this._controls.push(gandalfFormGroup);
		return gandalfFormGroup;
	}

	/**
	 * Gets the current value of the form array.
	 */
	get value(): ReadonlyGandalfFormModel<T>[] {
		return this.formArray.value;
	}

}
