
// @ts-ignore
import { CreatePatientDocumentRequest } from './create-patient-document-request';

// @ts-ignore
import { LegacyAddressResponse } from './legacy-address-response';

// @ts-ignore
import { LegacyPatientNameResponse } from './legacy-patient-name-response';

// @ts-ignore
import { LegacyProviderResponse } from './legacy-provider-response';

// @ts-ignore
import { LegacyReferenceDataResponse } from './legacy-reference-data-response';

// @ts-ignore
import { PatientDocumentSummaryResponse } from './patient-document-summary-response';

// @ts-ignore
import { QueryResultFieldResponse } from './query-result-field-response';

// @ts-ignore
import { QueryResultResponse } from './query-result-response';

// @ts-ignore
import { QueryResultValueResponse } from './query-result-value-response';

// @ts-ignore
import { QueryResultsResponse } from './query-results-response';

// @ts-ignore
import { RunQueryRequest } from './run-query-request';


/* istanbul ignore next */
export const MODEL_STORE: any = {

	CreatePatientDocumentRequest,

	LegacyAddressResponse,

	LegacyPatientNameResponse,

	LegacyProviderResponse,

	LegacyReferenceDataResponse,

	PatientDocumentSummaryResponse,

	QueryResultFieldResponse,

	QueryResultResponse,

	QueryResultValueResponse,

	QueryResultsResponse,

	RunQueryRequest,

};
