import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicModalRef, ModalConfig } from 'morgana';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'pms-pdf-viewer-modal',
	templateUrl: './pdf-viewer-modal.component.html',
})
export class PdfViewerModalComponent implements OnInit, OnDestroy {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('pdfViewer')
	pdfViewer: NgxExtendedPdfViewerComponent;

	title: string;
	fileUrl: string;
	fileName: string;
	openPrint: boolean;

	constructor(
		private dynamicModalRef: DynamicModalRef,
		public ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
		public modalConfig: ModalConfig,
	) {
	}

	ngOnInit(): void {
		this.parseModalConfig(this.modalConfig.data);
	}

	onLoad() {
		if (this.openPrint) {
			this.ngxExtendedPdfViewerService.print();
		}
	}

	ngOnDestroy(): Promise<void> {
		return this.pdfViewer.ngOnDestroy();
	}

	parseModalConfig(data: any) {
		this.title = data.title;
		this.fileUrl = data.fileUrl;
		this.fileName = data.fileName;
		this.openPrint = data.openPrint;
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}

}
