import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GridUtil, TypeSafeModalManagerService, YesNoPipe } from 'morgana';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { OptionItemResponse } from '@core/option-item/option-item.service';
import { PracticeLocation } from '@core/security-manager/security-manager.service';
import { UserLocationsService } from '@core/user-locations/user-locations.service';
import { _isNil } from '@core/lodash/lodash';
import { TrizettoExternalCredentialResponse } from '@gandalf/model/trizetto-external-credential-response';
import { TrizettoExternalCredentialModalComponent } from './trizetto-external-credential-modal/trizetto-external-credential-modal.component';
import { FormattedTrizettoExternalCredentialResponse, TrizettoTemplateService } from './trizetto-template.service';


export enum FilterOptions {
	ALL = 'All',
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
}

@Component({
	selector: 'pms-trizetto-template',
	templateUrl: './trizetto-template.component.html',
	providers: [TypeSafeModalManagerService],
})
export class TrizettoTemplateComponent implements OnInit {

	@ViewChild('agGrid')
	agGrid: AgGridAngular<FormattedTrizettoExternalCredentialResponse>;

	@ViewChild('enableDisableButtonColumn')
	enableDisableButtonColumn: TemplateRef<any>;

	gridOptions = GridUtil.buildGridOptions<FormattedTrizettoExternalCredentialResponse>({
		pagination: false,
		rowClass: 'row-link',
	});
	trizettoExternalCredentials: FormattedTrizettoExternalCredentialResponse[];
	gridColumns: ColDef[];
	isUpdating: boolean;
	locations: OptionItemResponse<PracticeLocation, number>[];
	statusFilter: FilterOptions;
	statusFilterOptions = [
		{label: 'All', value: FilterOptions.ALL},
		{label: 'Active', value: FilterOptions.ACTIVE},
		{label: 'Inactive', value: FilterOptions.INACTIVE},
	];

	constructor(
		private trizettoTemplateService: TrizettoTemplateService,
		private userLocationsService: UserLocationsService,
		private typeSafeModalManagerService: TypeSafeModalManagerService,
		private yesNoPipe: YesNoPipe,
	) {
	}

	ngOnInit() {
		this.statusFilter = FilterOptions.ACTIVE;
		this.getData();
	}

	getData() {
		this.trizettoTemplateService.findTrizettoExternalCredentials().subscribe(credentials => {
			this.trizettoExternalCredentials = credentials;
			this.locations = this.getAvailableLocations();
		});
	}

	getAvailableLocations() {
		return this.userLocationsService.getAllActiveLocations()
			// Filter out locations already assigned a credential
			.filter(location => !this.trizettoExternalCredentials.find(credential => credential.locations.find(credentialLocation => credentialLocation.id === location.id)));
	}

	onGridReady(_event: GridReadyEvent) {
		this.buildGridColumns();
		this.filterTable();
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildColumn('Site ID', 'userName', {
				minWidth: 105,
			}),
			GridUtil.buildColumn('Password', 'password', {
				minWidth: 105,
			}),
			GridUtil.buildColumnWithEllipses('Location(s)', 'formattedLocationListString', {
				flex: 1,
				minWidth: 150,
			}),
			GridUtil.buildColumn('Status', 'active', {
				width: 105,
				valueGetter: (params) => this.yesNoPipe.transform(params.data.active, 'Active', 'Inactive'),
			}),
			GridUtil.buildButtonColumn('', this.enableDisableButtonColumn, {
				width: 45,
			}),
		];

		this.agGrid.api.setColumnDefs(this.gridColumns);
	}

	filterTable() {
		GridUtil.applyFilter(this.agGrid, this.applyStatusFilter, 'active', this.statusFilter);
	}

	applyStatusFilter = () => !_isNil(this.statusFilter) && this.statusFilter !== FilterOptions.ALL;

	openModalWithRefresh(data?: TrizettoExternalCredentialResponse) {
		this.openTrizettoCredentialDetailModal(data).onClose.subscribe(refresh => {
			if (refresh) {
				this.getData();
			}
		});
	}

	updateStatus(data: TrizettoExternalCredentialResponse) {
		if (this.isUpdating) {
			return;
		}

		this.isUpdating = true;
		if (data.active) {
			this.disableTrizettoExternalCredential(data.trizettoExternalCredentialId);
		} else {
			this.enableTrizettoExternalCredential(data);
		}
	}

	disableTrizettoExternalCredential(credentialId: number) {
		this.trizettoTemplateService.disableTrizettoExternalCredential(credentialId).subscribe(() => {
			this.getData();
			this.isUpdating = false;
		});
	}

	enableTrizettoExternalCredential(data: TrizettoExternalCredentialResponse) {
		this.openTrizettoCredentialDetailModal(data).onClose.subscribe(() => {
			this.getData();
			this.isUpdating = false;
		});
	}

	private openTrizettoCredentialDetailModal(data?: TrizettoExternalCredentialResponse) {
		return this.typeSafeModalManagerService.open(TrizettoExternalCredentialModalComponent, {
			trizettoExternalCredentialId: data?.trizettoExternalCredentialId,
			userName: data?.userName,
			password: data?.password,
			assignedLocations: data?.locations,
			availableLocations: this.locations,
		});
	}
}
