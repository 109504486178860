import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FileService, FileType } from '@core/file/file.service';
import { FolderService, SimpleFolder } from '@core/folder/folder.service';
import { DialogUtil, EnumUtil, EXPORT_TEXT, GridUtil, ModalManagerService, PAGE_LENGTH, PAGE_LENGTH_LIST, TableToolItem } from 'morgana';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _isNil } from '@core/lodash/lodash';
import { PatientService } from '@core/patient/patient.service';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientFolderIdentifier, PreferenceName, ResourceAuthority } from '@gandalf/constants';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';
import { UpdatePatientFileRequest } from '@gandalf/model/update-patient-file-request';
import { PatientFileUploadModalComponent } from '@shared/component/directory-tree/file-uploader-modal/patient-file-upload-modal.component';
import { IncorporateDocumentModalComponent } from '@shared/component/incorporate-document-modal/incorporate-document-modal.component';
import { UpdateFileModalComponent } from '@shared/component/update-file-modal/update-file-modal.component';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { TreeNode } from '@shared/interfaces/treeview';
import { FileSizePipe } from '@shared/pipes/file-size/file-size.pipe';
import { ContextMenuClickEventArgs, ContextMenuItemModel, GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { PageSettingsModel } from '@syncfusion/ej2-grids';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { permissionToken } from '@core/injection-tokens/permission-tokens/permission-tokens';
import { DrawingToolModalComponent } from '../../../drawing-tool/drawing-tool-modal/drawing-tool-modal.component';

export interface FormattedFileOrFolder extends PatientFileListResponse {
	formattedType: FileType;
	formattedSize?: string;
}

export const EditableImageTypes = [FileType.JPEG.valueOf(), FileType.PNG.valueOf(), FileType.GIF.valueOf()];

@Component({
	selector: 'pms-folder-file-list',
	templateUrl: './folder-file-list.component.html',
	providers: [ModalManagerService],
})
export class FolderFileListComponent implements OnInit, OnDestroy {

	@Input()
	patientId: number;

	@Input()
	set folder(value: SimpleFolder) {
		this._folder = value;
		this.getData(false);
	}

	get folder(): SimpleFolder {
		return this._folder;
	}
	private _folder: SimpleFolder;

	@Input()
	gridState: string;

	initialGridState: string;

	@Output()
	private folderRemoved = new EventEmitter<void>();

	@Output()
	folderSelected = new EventEmitter<SimpleFolder>();

	@Output()
	gridStateChange = new EventEmitter<string>();

	@ViewChild('grid')
	grid: GridComponent;

	readonly canDeleteFolder = inject(permissionToken(ResourceAuthority.PATIENTS_DELETE_FOLDER));

	pageSettings: PageSettingsModel = {
		pageSize: PAGE_LENGTH.PAGE_10,
		pageSizes: PAGE_LENGTH_LIST,
	};
	toolbar: TableToolItem[] = [
		{
			text: EXPORT_TEXT,
			iconCss: 'fa fa-download',
			dataTestId: 'Export',
		},
	];
	contextMenuItems: ContextMenuItemModel[] = [
		{
			text: 'Cut File',
			id: 'CUT',
		},
		{
			text: 'Paste File',
			id: 'PASTE',
		},
	];
	files: FormattedFileOrFolder[] = [];
	filesAndFolders: FormattedFileOrFolder[];
	isSearching = false;
	tableDateFormat = TABLE_DATE_FORMATS.MM_DD_YYYY_H_MM_A;
	canScan = false;
	lastSelectedRow: FormattedFileOrFolder;
	mostRecentlyCutItem: FormattedFileOrFolder;
	private contextMenuIsOpening = false;
	private onClickEventListener: () => void;
	private gridSubscription: Subscription;
	private ACTION_COMPLETE_DEBOUNCE = 150;

	dataLoaded = new Subject();
	tableIsRendered = new Subject();
	actionComplete = new Subject();

	constructor(
		public patientService: PatientService,
		public fileSizePipe: FileSizePipe,
		public modalManagerService: ModalManagerService,
		public folderService: FolderService,
		public securityManagerService: SecurityManagerService,
		private eventsManagerService: EventsManagerService,
		private fileService: FileService,
	) {
		combineLatest([
			this.tableIsRendered,
			this.dataLoaded.pipe(switchMap(() => this.actionComplete.pipe(debounceTime(this.ACTION_COMPLETE_DEBOUNCE)))),
		]).pipe(
			take(1),
		).subscribe(() => {
			this.restoreGridAndSubscribeForState();
		});
	}

	ngOnInit() {
		this.canScan = this.securityManagerService.preferenceValueIsOn(PreferenceName.SCANNING_ENABLED.value, 'true');
		this.initialGridState = this.gridState;
	}

	getData(keepCurrentPage = true) {
		if (!_isNil(this.folder)) {
			this.refreshFiles(keepCurrentPage);
		} else {
			this.files = [];
			this.filesAndFolders = [];
		}
	}

	refreshFiles(keepCurrentPage = true) {
		let currentPage;
		if (this.grid && this.grid.pageSettings) {
			currentPage = this.grid.pageSettings.currentPage;
		}
		this.isSearching = true;
		this.patientService.findPatientFilesInFolder(
			this.patientId,
			this.folder.folderId,
		).subscribe((results) => {
			this.files = results.map((file) => this.formatFile(file));
			this.isSearching = false;
			this.filesAndFolders = [...this.getSubFolders().map((folder) => this.formatFolder(folder)), ...this.files];
			if (keepCurrentPage && !_isNil(currentPage)) {
				this.setCurrentPageOnActionComplete(currentPage);
			}
			this.dataLoaded.next(true);
		});
	}

	private setCurrentPageOnActionComplete(currentPage) {
		this.actionComplete.pipe(debounceTime(this.ACTION_COMPLETE_DEBOUNCE), take(1)).subscribe(() => {
			this.grid.goToPage(currentPage);
		});
	}

	private restoreGridAndSubscribeForState() {
		if (!_isNil(this.initialGridState)) {
			// Only want to set the grid state to the initial state from the store
			GridUtil.restoreGridOptions(this.grid, this.initialGridState);
			this.initialGridState = null;
		}
		if (!this.gridSubscription) {
			this.gridSubscription = this.getGridActionCompleteEmitter().subscribe(() => {
				this.gridStateChange.emit(GridUtil.getGridOptions(this.grid));
			});
		}
	}

	private getGridActionCompleteEmitter() {
		return this.grid.actionComplete;
	}

	private getSubFolders() {
		return this.folder ? this.folder.children || [] : [];
	}

	rowSelected(event) {
		const data: FormattedFileOrFolder = event.data;
		this.lastSelectedRow = data;
		if (!this.contextMenuIsOpening) {
			if (data.formattedType === FileType.FOLDER) {
				this.folderSelected.emit({folderId: data.folderId, name: data.fileName, children: []});
			} else {
				this.openFile(data);
			}
			this.grid.clearSelection();
		}
	}

	formatFolder(folder: TreeNode): FormattedFileOrFolder {
		return {
			...new PatientFileListResponse(),
			fileName: folder.label,
			formattedType: FileType.FOLDER,
			folderId: folder.data as string,
		};
	}

	formatFile(file: PatientFileListResponse): FormattedFileOrFolder {
		return {
			...file,
			formattedType: FileService.getFileTypeFromMimeType(file.mimeType),
			formattedSize: this.fileSizePipe.transform(file.fileSize),
		};
	}

	openFile(data: FormattedFileOrFolder) {
		const request = new UpdatePatientFileRequest();
		request.patientFileId = data.patientFileId;
		request.name = data.fileName;
		request.description = data.fileDescription;

		this.modalManagerService.open(UpdateFileModalComponent, {
			data: {
				request,
				additionalInfo: {
					createdOn: data.createdOn,
					createdBy: data.createdByUsername,
					fileType: data.mimeType,
					fileSize: data.formattedSize,
				},
			},
		}).onClose.subscribe(result => {
			if (result) {
				this.patientService.updatePatientFile(result).subscribe(() => {
					this.refreshFiles();
				});
			}
		});
	}

	deleteItem(data: FormattedFileOrFolder) {
		if (this.isFolder(data)) {
			this.deleteFolder(data);
		} else {
			this.deleteFile(data);
		}

	}

	deleteFolder(data: FormattedFileOrFolder) {
		this.folderService.deleteFolder(data.folderId, data.fileName).subscribe(() => {
			this.folderRemoved.emit();
		});
	}

	deleteFile(data: FormattedFileOrFolder) {
		const confirmation = DialogUtil.confirm({
			title: `Delete File`,
			content: `Are you sure you want to delete the file "${data.fileName}"?`,
			okButton: {
				click: () => this._deleteFile(data, confirmation),
			},
		});
	}

	isFolder(data: FormattedFileOrFolder) {
		return data.formattedType === FileType.FOLDER;
	}

	isDeletable(data: FormattedFileOrFolder) {
		return !this.isFolder(data) || (this.canDeleteFolder && _isNil(EnumUtil.findEnumByValue(data.folderId, PatientFolderIdentifier)));
	}

	previewItem(data: FormattedFileOrFolder) {
		this.fileService.viewOrDownloadPatientFile(this.patientId, data);
	}

	isPreviewable(data: FormattedFileOrFolder) {
		return !this.isFolder(data) && this.fileService.isMimeTypePreviewable(data.mimeType);
	}

	isDownloadable(data: FormattedFileOrFolder) {
		return data.formattedType !== FileType.FOLDER;
	}

	downloadItem(data: FormattedFileOrFolder) {
		this.fileService.downloadPatientFile(this.patientId, data.patientFileId);
	}

	openUploadModal() {
		this.modalManagerService.open(PatientFileUploadModalComponent, {data: {folderId: this.folder.folderId, patientId: this.patientId}}).onClose.subscribe(
			fileUploaded => {
				if (fileUploaded) {
					this.refreshFiles();
				}
			},
		);
	}

	buildScannerParams() {
		return {
			patientId: this.patientId,
			folderId: this.folder.folderId,
			parentId: '',
			entityId: '',
			encounterId: '0',
		};
	}

	scan() {
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.SCANNER.SCANNER_MODULE_SCAN_DOCUMENT, this.buildScannerParams());
		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.MODAL.MODAL_CLOSED, () => {
			this.refreshFiles();
		});
	}

	openDrawingToolModal() {
		this.modalManagerService.open(DrawingToolModalComponent, {data: {folderId: this.folder.folderId, patientId: this.patientId}}).onClose.subscribe(refresh => {
			if (refresh) {
				this.refreshFiles();
			}
		});
	}

	isImageEditable(data: FormattedFileOrFolder) {
		return EditableImageTypes.includes(data.formattedType);
	}

	editImage(data: FormattedFileOrFolder) {
		this.modalManagerService.open(DrawingToolModalComponent, {
			data: {
				folderId: this.folder.folderId,
				patientId: this.patientId,
				fileData: data,
			},
		}).onClose.subscribe(refresh => {
			if (refresh) {
				this.refreshFiles();
			}
		});
	}

	private _deleteFile(data: FormattedFileOrFolder, dialog: Dialog) {
		this.patientService.deletePatientFile(data.patientFileId).subscribe(() => {
			this.refreshFiles();
			dialog.close();
		});
	}

	contextMenuClick(event: ContextMenuClickEventArgs) {
		if (event.item.id === 'CUT') {
			if (this.lastSelectedRow.formattedType !== FileType.FOLDER) {
				this.mostRecentlyCutItem = this.lastSelectedRow;
			}
		} else if (event.item.id === 'PASTE') {
			const pasteLocation = this.lastSelectedRow;
			let pasteFolderId = this.folder.folderId;
			if (pasteLocation.formattedType === FileType.FOLDER) {
				pasteFolderId = pasteLocation.folderId;
			}
			this.patientService.movePatientFile(this.mostRecentlyCutItem.patientFileId, pasteFolderId).subscribe(() => {
				this.refreshFiles();
				this.mostRecentlyCutItem = null;
			});
		}
		this.grid.clearSelection();
	}

	contextMenuOpeningClick() {
		this.contextMenuIsOpening = true;
		this.addWindowClickEventListener();
	}

	private addWindowClickEventListener() {
		if (_isNil(this.onClickEventListener)) {
			this.onClickEventListener = () => this.contextMenuClosing();
			window.addEventListener('click', this.onClickEventListener);
		}
	}

	contextMenuOpeningFromSyncfusion(event) {
		if (_isNil(event.rowInfo.rowIndex)) {
			this.grid.clearSelection();
		}
		this.disableContextMenuItems(event);
	}

	private disableContextMenuItems(event) {
		// Disable paste if no item has been cut
		event.items[0].controlParent.enableItems(['Paste File'], !_isNil(this.mostRecentlyCutItem));
		// Disable cut if this is either not a row or is a folder type
		event.items[0].controlParent.enableItems(
			['Cut File'],
			!_isNil(event.rowInfo.rowIndex) && event.rowInfo.rowData.formattedType !== FileType.FOLDER,
		);
	}

	contextMenuClosing() {
		this.contextMenuIsOpening = false;
		this.grid.clearSelection();
		this.removeWindowEventListener();
	}

	private removeWindowEventListener() {
		if (!_isNil(this.onClickEventListener)) {
			window.removeEventListener('click', this.onClickEventListener);
			this.onClickEventListener = null;
		}
	}

	ngOnDestroy() {
		this.removeWindowEventListener();
	}

	/* istanbul ignore next: modal open */
	incorporateDocument(data: FormattedFileOrFolder) {
		this.modalManagerService.open(IncorporateDocumentModalComponent, {data: {patientFileId: data.patientFileId, patientId: this.patientId}});
	}

	isParseable(data: FormattedFileOrFolder) {
		return data.formattedType === FileType.XML;
	}
}
